import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/images/wx.jpg'


const _hoisted_1 = { class: "page-footer" }

const copyRightMsg =
  "Copyright © 2014-2024 EEOA - All rights reserved. 京ICP备18039479号-1";

export default /*@__PURE__*/_defineComponent({
  __name: 'PageFooter',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"connect-info\" data-v-443d631c><div class=\"card\" data-v-443d631c><div class=\"card-image\" data-v-443d631c><img src=\"" + _imports_0 + "\" alt=\"微信公众号\" data-v-443d631c></div><div class=\"title\" data-v-443d631c>关注微信公众号</div></div><div class=\"dotline\" data-v-443d631c></div><div class=\"card\" data-v-443d631c><div class=\"card-image\" data-v-443d631c><img src=\"" + _imports_0 + "\" alt=\"微信客服\" data-v-443d631c></div><div class=\"title\" data-v-443d631c>添加微信客服</div></div><div class=\"dotline\" data-v-443d631c></div><div class=\"infos\" data-v-443d631c><div class=\"info\" data-v-443d631c><i class=\"icon tel\" data-v-443d631c></i> 电话：13911231074 </div><div class=\"info\" data-v-443d631c><i class=\"icon email\" data-v-443d631c></i> 邮箱：kf@liangziqishi.cn </div><div class=\"info\" data-v-443d631c><i class=\"icon address\" data-v-443d631c></i> 北京海淀区西三旗文化科技园 </div></div></div>", 1)),
    _createElementVNode("p", { class: "copy-right" }, _toDisplayString(copyRightMsg))
  ]))
}
}

})