<script lang="ts" name="HomeView">
import { isMobile } from "@/utils";
import { defineComponent } from "vue";
import { ArrowLeftBold, ArrowRightBold } from "@element-plus/icons";
import image1 from "@/assets/images/carousel/1.png";
import image2 from "@/assets/images/carousel/2.png";
import image3 from "@/assets/images/carousel/3.png";
import image4 from "@/assets/images/carousel/4.png";
import cardImage0 from "@/assets/images/home/cards/0.png";
import cardImage1 from "@/assets/images/home/cards/1.png";
import cardImage2 from "@/assets/images/home/cards/2.png";
import cardImage3 from "@/assets/images/home/cards/3.png";
import cardImage4 from "@/assets/images/home/cards/4.png";

import lrImage1 from "@/assets/images/home/1.png";
import lrImage2 from "@/assets/images/home/2.png";
import lrImage3 from "@/assets/images/home/3.png";
import lrImage4 from "@/assets/images/home/4.png";
import mobileImg1 from "@/assets/images/home/mobile1.png";
import mobileImg2 from "@/assets/images/home/mobile2.png";
import mobileImg3 from "@/assets/images/home/mobile3.png";
import mobileImg4 from "@/assets/images/home/mobile4.png";

import btn1 from "@/assets/images/home/btn1.png";
import btn2 from "@/assets/images/home/btn2.png";
import btn3 from "@/assets/images/home/btn3.png";
import btn4 from "@/assets/images/home/btn4.png";

import aiImage from "@/assets/images/home/ai.png";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
export default defineComponent({
  name: "HomeView",
  components: {
    // HelloWorld,
    ArrowLeftBold,
    ArrowRightBold,
  },
  data() {
    return {
      carouselList: [image1, image2, image3, image4],
      cardImageList: [
        {
          image: cardImage0,
          title: "看动画学英语",
          info: "通过趣味动画，孩子在轻松愉快的观看中提高英语听力、词汇量、口语表达和文化理解能力，激发学习兴趣。同时结合“挑战练习”和单词学习游戏，让孩子边玩边学，巩固所学知识，轻松迈向语言学习新高度！",
          tips: ["听力提升", "激发兴趣", "语境营造", "词汇积累"],
        },
        {
          image: cardImage1,
          title: "听力提升",
          info: "动画中的英语语言通常清晰、标准，并配合适当的语速和语调，非常适合孩子的听力训练。孩子通过观看和聆听动画，能够不断接收到正确的语音输入，进而提高他们的听力理解能力。随着时间的推移，孩子的听力水平逐渐提升，他们会习惯并能够理解更多的英语表达方式，对未来的口语交流和听力考试大有裨益。",
          // tips: ["听力提升", "激发兴趣", "语境营造", "词汇积累"],
        },
        {
          image: cardImage2,
          title: "词汇积累",
          info: "动画通常设计有多样的场景和丰富的角色对话，这些对话中包含了大量的日常英语词汇和常用句型。孩子在观看动画的过程中，不仅能够接触到词汇，还能通过场景中的反复出现加深记忆，达到自然而然积累词汇的效果。对于孩子来说，这种词汇积累不仅仅是记忆词汇表，而是通过实际应用场景学到的真实语言，使得语言学习更具实用性。",
          // tips: ["听力提升", "激发兴趣", "语境营造", "词汇积累"],
        },
        {
          image: cardImage3,
          title: "激发兴趣",
          info: "动画通过生动的画面和富有趣味性的情节，能够迅速抓住孩子的注意力，激发他们学习英语的兴趣。动画中的角色设定故事情节和多彩的画面设计，都能让孩子在无压力的状态下，自然进入学习状态。这种轻松愉快的学习氛围，不仅能提高孩子的学习积极性，还能让他们在不知不觉中积累语言知识。",
          // tips: ["听力提升", "激发兴趣", "语境营造", "词汇积累"],
        },
        {
          image: cardImage4,
          title: "语境营造",
          info: "与传统的语言学习方式不同，动画能够为孩子提供一个仿真且沉浸的语言环境。在这个环境中，英语不再是抽象的概念，而是伴随角色互动、场景变化和故事进展而自然呈现。孩子们通过模仿动画中的角色对话，能更好地理解英语的实际运用，尤其是在真实生活场景中如何表达，帮助他们培养更强的语感，使得语言学习更具实践性和实用性。",
          // tips: ["听力提升", "激发兴趣", "语境营造", "词汇积累"],
        },
      ],
      activeCard: {
        image: "",
        title: "",
        info: "",
      },
      lrImages: [lrImage1, lrImage2, lrImage3, lrImage4],
      mobileImgs: [mobileImg1, mobileImg2, mobileImg3, mobileImg4],
      btns: [btn1, btn2, btn3, btn4],
      aiImage,
      pingjiaList: [
        {
          avatar: "https://avatars.githubusercontent.com/u/26485370?v=4",
          name: "张三",
          createAt: "2021-09-01",
          title: "很好的学习平台",
          content: "这是一个很好的学习平台，我孩子在这里学习了很多知识，",
        },
        {
          avatar: "https://avatars.githubusercontent.com/u/26485370?v=4",
          name: "李四",
          createAt: "2021-09-01",
          title: "很好的学习平台",
          content:
            "这是一个很好的学习平台，我孩子在这里学习了很多知识，老师也很负责，值得推荐。",
        },
        {
          avatar: "https://avatars.githubusercontent.com/u/26485370?v=4",
          name: "王五",
          createAt: "2021-09-01",
          title: "很好的学习平台",
          content:
            "这是一个很好的学习平台，我孩子在这里学习了很多知识，老师也很负责，值得推荐。",
        },
        {
          avatar: "https://avatars.githubusercontent.com/u/26485370?v=4",
          name: "张三",
          createAt: "2021-09-01",
          title: "很好的学习平台",
          content: "这是一个很好的学习平台，我孩子在这里学习了很多知识，",
        },
        {
          avatar: "https://avatars.githubusercontent.com/u/26485370?v=4",
          name: "李四",
          createAt: "2021-09-01",
          title: "很好的学习平台",
          content:
            "这是一个很好的学习平台，我孩子在这里学习了很多知识，老师也很负责，值得推荐。",
        },
        {
          avatar: "https://avatars.githubusercontent.com/u/26485370?v=4",
          name: "王五",
          createAt: "2021-09-01",
          title: "很好的学习平台",
          content:
            "这是一个很好的学习平台，我孩子在这里学习了很多知识，老师也很负责，值得推荐。",
        },
      ],
      isMobileFlag: false,
    };
  },
  mounted() {
    this.activeCard = this.cardImageList[0];
    this.isMobileFlag = isMobile();
  },
  methods: {
    handleMove(direction: string) {
      const pingjiaRef = this.$refs.pingjiaRef as HTMLElement;
      if (direction === "left") {
        pingjiaRef.scrollLeft -= 440;
      } else {
        pingjiaRef.scrollLeft += 440;
      }
    },
  },
});
</script>
<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js + TypeScript App" /> -->
    <!-- 轮播 -->
    <el-carousel height="36.4vw" arrow="never">
      <el-carousel-item v-for="(item, index) in carouselList" :key="index">
        <el-image fit="cover" :src="item" class="image" />
      </el-carousel-item>
    </el-carousel>
    <!-- 卡片内容 -->
    <div class="content-info">
      <h1>专研少儿英语我们是专业的</h1>
      <p>为新时代的孩子量身定制，用科技为学习英语赋能</p>
      <div class="info-card">
        <div class="show-cards">
          <el-image class="show-image" fit="cover" :src="activeCard.image" />
          <ul class="cards">
            <li
              v-for="(item, index) in cardImageList"
              :key="index"
              @click="activeCard = item"
            >
              <el-image class="card-item" fit="cover" :src="item.image" />
            </li>
          </ul>
        </div>
        <div class="msg">
          <div class="top-line">
            <h2>{{ activeCard.title }}</h2>
            <ul
              class="tip-list"
              v-if="activeCard.tips && activeCard.tips.length"
            >
              <li class="tip" v-for="tip in activeCard.tips" :key="tip">
                {{ tip }}
              </li>
            </ul>
          </div>
          <p>{{ activeCard.info }}</p>
        </div>
      </div>
      <div class="lr-card">
        <div class="msg lr-msg">
          <el-image
            v-if="isMobileFlag"
            class="mobile-image"
            :src="mobileImgs[0]"
          ></el-image>
          <h2>英语能力测评</h2>
          <p>
            精准测评界面，通过分析孩子的语言能力，为其量身推荐合适的练习题目。科学的反馈机制帮助家长和学生直观了解学习成果，并针对薄弱环节提供专项训练，提升学习效率。
          </p>
          <ul class="tip-list">
            <li class="tip">学习方式更加自主和灵活</li>
            <li class="tip">成本低</li>
            <li class="tip">学习氛围更轻松</li>
          </ul>
        </div>
        <div v-if="!isMobileFlag" class="lr-image">
          <el-image
            style="height: 476px"
            class="bg-image"
            fit="cover"
            :src="lrImages[0]"
          />
        </div>
      </div>
      <div class="lr-card rl-style">
        <div class="msg lr-msg">
          <el-image
            v-if="isMobileFlag"
            class="mobile-image"
            :src="mobileImgs[1]"
          ></el-image>
          <h2>AI 聊天</h2>
          <p>
            模拟真实场景，孩子可与AI展开多角色对话，体验如购物、旅行等多样化主题，提高听力和语感。互动式对话帮助孩子增强语言表达能力，让英语交流更加自然自信！
          </p>
          <ul class="tip-list">
            <li class="tip">互动式学习</li>
            <li class="tip">模拟真实环境</li>
            <li class="tip">AI智能辅助</li>
          </ul>
        </div>
        <div v-if="!isMobileFlag" class="lr-image">
          <el-image class="bg-image" fit="cover" :src="lrImages[1]" />
        </div>
      </div>
      <div class="lr-card">
        <div class="msg lr-msg">
          <el-image
            v-if="isMobileFlag"
            class="mobile-image"
            :src="mobileImgs[2]"
          ></el-image>
          <h2>AI 听写</h2>
          <p>
            通过音节划分、单词提示等智能功能，让听写变得有趣高效。科学记忆法帮助孩子告别枯燥的单词记忆，轻松掌握更多词汇，提高语言学习效率。
          </p>
          <ul class="tip-list">
            <li class="tip">AI高效学习</li>
            <li class="tip">多元化学习方式</li>
            <li class="tip">精准教学</li>
          </ul>
        </div>
        <div v-if="!isMobileFlag" class="lr-image">
          <el-image class="bg-image" fit="cover" :src="lrImages[2]" />
        </div>
      </div>
      <div class="lr-card rl-style">
        <div class="msg lr-msg">
          <el-image
            v-if="isMobileFlag"
            class="mobile-image"
            :src="mobileImgs[3]"
          ></el-image>
          <h2>用户上传视频制作课程</h2>
          <p>
            孩子和家长可以选择喜爱的电影或电视剧片段制作个性化课程。通过生动有趣的情境学习，让孩子在熟悉的画面中自然吸收语言知识，告别传统枯燥模式，激发学习动力。
          </p>
          <ul class="tip-list">
            <li style="margin-right: 0px" class="tip">个性化学习方式</li>
            <li style="margin-right: 0px" class="tip">激发自主能动性</li>
            <li class="tip">创新学习模式</li>
          </ul>
        </div>
        <div v-if="!isMobileFlag" class="lr-image">
          <el-image class="bg-image" fit="cover" :src="lrImages[3]" />
        </div>
      </div>
    </div>
    <!-- AI内容 -->
    <div class="content-ai">
      <h1>让 AI 陪你一起学英语，让你学的更加简单有趣。</h1>
      <div class="content">
        <ul class="btns">
          <li v-for="(btn, index) in btns" :key="index">
            <el-image :src="btn" />
          </li>
        </ul>
        <div class="show-image">
          <el-image :src="aiImage" />
          <p style="font-size: 18px">
            让AI陪你一起学英语，让你学的更加简单有趣，让AI陪你一起学英语，让你学的更加简单有趣。
          </p>
        </div>
      </div>
    </div>
    <!-- 客户评价 -->
    <div class="pingjia">
      <h1>客户评价</h1>
      <div class="area">
        <div class="content" ref="pingjiaRef">
          <ul class="pingjia-list">
            <li
              class="pingjia-item"
              v-for="(item, index) in pingjiaList"
              :key="index"
            >
              <div class="first-line">
                <span class="title">
                  <el-avatar :size="60" :src="item.avatar" />
                  {{ item.name }}
                </span>
                <span class="date">{{ item.createAt }}</span>
              </div>
              <div class="number">
                <i class="icon star"></i>
                <i class="icon star"></i>
                <i class="icon star"></i>
                <i class="icon star"></i>
                <i class="icon star"></i>
              </div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.content }}</p>
            </li>
          </ul>
        </div>
        <i class="icon-btn left" @click="handleMove('left')">
          <el-icon><ArrowLeftBold /></el-icon>
        </i>
        <i class="icon-btn right" @click="handleMove('right')">
          <el-icon><ArrowRightBold /></el-icon>
        </i>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.home {
  width: 100%;
  overflow: hidden;
  .content-info {
    background: #fffdf1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .info-card {
      width: 1284px;
      border-radius: 22px;
      background: #ffffff;
      border: 1px solid #e1dbb7;
      margin: 28px 0 0;
      padding: 39px 47px;
    }
  }
}
.show-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .show-image {
    flex: 1;
    height: 564px;
    margin-right: 44px;
    border-radius: 22px;
  }
  .cards {
    list-style: none;
    width: 264px;
    height: 564px;
    overflow: auto;
    li {
      width: 264px;
      height: 164px;
      margin: 10px 0;
      border-radius: 22px;
      overflow: hidden;
      cursor: pointer;
      .card-item {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.tip {
  padding-left: 30px;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  color: #5d310a;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: url("@/assets/images/duigou.png") no-repeat;
  }
}
.msg {
  margin: 30px 0 0;
  text-align: left;
  .top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tip-list {
    display: flex;
    list-style: none;
    .tip + .tip {
      margin-left: 40px;
    }
  }
  p {
    margin-top: 24px;
  }
}
.lr-card {
  position: relative;
  margin-top: 80px;
  &:last-child {
    margin-bottom: 80px;
  }
  &::after {
    clear: both;
  }
  .lr-msg {
    display: inline-block;
    width: 808px;
    margin-top: 45px;
    margin-right: 472px;
    padding: 64px 168px 40px 75px;
    border-radius: 22px;
    opacity: 1;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(255, 173, 32, 0.3);
    .tip-list {
      justify-content: start;
      flex-wrap: wrap;
      .tip {
        margin: 40px 40px 0 0;
      }
      .tip:last-child {
        margin-right: 0;
      }
      .tip + .tip {
        margin-left: 0;
      }
    }
  }
  .lr-image {
    position: absolute;
    right: -10px;
    top: 0;
    overflow: hidden;
    .bg-image {
      width: 598px;
      height: 374px;
    }
  }
}
.rl-style {
  .lr-msg {
    margin-right: 0;
    margin-left: 472px;
    padding: 40px 60px 40px 168px;
  }
  .lr-image {
    top: 0;
    left: -10px;
    right: unset;
  }
}
.content-ai {
  background: #fdf4ce;
  overflow: hidden;
  padding: 0 0 120px;
  .content {
    width: 1280px;
    margin: 0 auto 0;
    display: flex;
    background: url("@/assets/images/home/circle.png") no-repeat;
    background-size: 360px 360px;
    background-position: 180px 0px;
    .btns {
      margin-top: 100px;
      list-style: none;
      li:first-child {
        transform: translateX(40px);
      }
      li + li {
        margin-top: 20px;
      }
    }
  }
}
.pingjia {
  background: #fffdf1;
  overflow: hidden;
  padding: 0 0 120px;
  .area {
    position: relative;
    width: 1280px;
    height: auto;
    margin: 0 auto;
  }
  .content {
    width: 1280px;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    .pingjia-list {
      display: flex;
      width: auto;
      list-style: none;
      margin-top: 60px;
      .pingjia-item {
        flex-grow: 0;
        flex-shrink: 0;
        width: 400px;
        height: 393px;
        padding: 42px 37px;
        border-radius: 11px;
        background: #ffffff;
        box-shadow: 0px 4px 10px 0px rgba(165, 115, 14, 0.1);
        text-align: left;
        .first-line {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .title {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: bold;
          line-height: normal;
          color: #3d3d3d;
          .el-avatar {
            margin-right: 20px;
          }
        }
        .date {
          font-size: 18px;
          color: #666666;
        }
        .number {
          margin: 20px 0;
          i {
            display: inline-block;
            width: 25px;
            height: 25px;
            background: url("@/assets/images/home/star.png") no-repeat;
            background-size: 25px 25px;
          }
          i + i {
            margin-left: 10px;
          }
        }
        h4 {
          position: relative;
          font-size: 24px;
          font-weight: bold;
          color: #3d3d3d;
          margin: 14px 0;
          &::after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background: url("@/assets/images/home/yinhao.png") no-repeat;
            background-size: 16px 16px;
            background-position: right center;
            width: 16px;
            height: 16px;
          }
        }
        p {
          font-size: 18px;
          color: #666666;
        }
      }
      .pingjia-item + .pingjia-item {
        margin-left: 40px;
      }
    }
  }
  .icon-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #70280b;
    text-align: center;
    line-height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #c8c5b0;
    border-radius: 20px;
    &:hover {
      background: #ff9608;
      color: #ffffff;
    }
    &.left {
      left: -60px;
    }
    &.right {
      right: -60px;
    }
  }
}
@media screen and (max-width: 960px) {
  .home {
    .content-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .info-card {
        width: calc(100vw - 2rem);
        border-radius: 22px;
        padding: 1rem;
      }
    }
  }
  .show-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .show-image {
      width: 100%;
      height: auto;
      border-radius: 1rem;
      margin: 0;
    }
    .cards {
      list-style: none;
      width: 100%;
      height: auto;
      overflow: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.2rem 0;
      li {
        width: 18%;
        height: auto;
        border-radius: 0;
        overflow: hidden;
        cursor: pointer;
        .card-item {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .msg {
    margin-top: 1rem;
    .top-line {
      flex-direction: column;
      align-items: start;
      text-align: left;
    }
    .tip-list {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      list-style: none;
      .tip {
        width: calc(45vw - 20px);
        font-size: 1rem;
        margin-top: 0.5rem;
      }
      .tip + .tip {
        margin: 0;
        margin-top: 0.5rem;
      }
    }
    p {
      margin-top: 0.5rem;
    }
  }
  .lr-card {
    margin-top: 2rem;
    &:last-child {
      margin-bottom: 80px;
    }
    &::after {
      clear: both;
    }
    .lr-msg {
      box-sizing: border-box;
      display: block;
      width: calc(100vw - 2rem);
      margin: 0 1rem;
      padding: 1rem;
      .mobile-image {
        width: 100%;
        margin-bottom: 1rem;
      }
      .tip-list {
        justify-content: start;
        align-items: start;
        flex-wrap: wrap;
        .tip {
          width: auto;
          font-size: 1rem;
          margin: 0.5rem 0.5rem 0 0;
        }
        .tip:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .content-ai {
    padding: 0 0 2rem;
    h1 {
      padding: 0 1rem;
    }
    .content {
      width: calc(100vw - 2rem);
      margin: 0 auto;
      display: block;
      background: none;
      .btns {
        width: 100%;
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        list-style: none;
        li {
          width: 40vw;
          margin-top: 20px;
        }
        li:first-child {
          transform: none;
        }
      }
    }
  }
  .pingjia {
    background: #fffdf1;
    overflow: hidden;
    padding: 0 0 2rem;
    h1 {
      margin: 2rem auto 0;
    }
    .area {
      width: calc(100vw - 2rem);
    }
    .content {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-behavior: smooth;
      .pingjia-list {
        display: flex;
        width: 100%;
        flex-direction: column;
        list-style: none;
        margin: 1rem auto;
        .pingjia-item {
          width: 100%;
          height: auto;
          padding: 1rem 1rem 2rem;
          border-radius: 1rem;
        }
        .pingjia-item + .pingjia-item {
          margin: 0;
          margin-top: 1rem;
        }
      }
    }
    .icon-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #70280b;
      text-align: center;
      line-height: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #c8c5b0;
      border-radius: 20px;
      &:hover {
        background: #ff9608;
        color: #ffffff;
      }
      &.left {
        left: -60px;
      }
      &.right {
        right: -60px;
      }
    }
  }
}
</style>
