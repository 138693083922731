<template>
  <div class="page-header">
    <div class="show-area">
      <div class="logo">
        <img
          style="width: 50px; height: 50px"
          src="../assets/images/logo.png"
          alt="logo"
        />
        柠檬学堂
      </div>
      <i
        v-if="isMobileFlag"
        @click="isShowMenu = !isShowMenu"
        class="icon menu"
      ></i>
      <ul v-if="!isMobileFlag" class="nav-list">
        <li
          class="nav-item"
          :class="currentRouteName === nav.value ? 'actived' : ''"
          v-for="nav in navList"
          :key="nav.value"
        >
          <router-link class="router-item" :to="{ name: nav.value }">
            {{ nav.label }}
          </router-link>
        </li>
      </ul>
      <el-drawer
        v-if="isMobileFlag"
        append-to-body
        v-model="isShowMenu"
        size="50%"
        :with-header="false"
        modal-class="drawer-menu"
      >
        <ul class="nav-list">
          <li
            class="nav-item"
            :class="currentRouteName === nav.value ? 'actived' : ''"
            v-for="nav in navList"
            :key="nav.value"
          >
            <router-link class="router-item" :to="{ name: nav.value }">{{
              nav.label
            }}</router-link>
          </li>
        </ul>
      </el-drawer>
    </div>
  </div>
</template>
<script lang="ts" setup name="PageHeader">
interface Option {
  value: string;
  label: string;
}
import { isMobile } from "@/utils";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
const isShowMenu = ref(false);
const isMobileFlag = ref(isMobile());
// 引入路由，动态展示路由meta信息
import { useRouter, useRoute } from "vue-router";
const routers = useRouter();
const currentRoute = useRoute();
const currentRouteName = ref(currentRoute.name as string);
const resetScrollAndShow = () => {
  const htmlDom = document.querySelector("html");
  if (htmlDom && htmlDom.scrollTop > 0) {
    htmlDom.scrollTo(0, 0);
  }
  isShowMenu.value = false;
};
watch(currentRoute, (to) => {
  resetScrollAndShow();
  currentRouteName.value = to.name as string;
});
const navList = ref<Option[]>([]);
routers.getRoutes().forEach((route) => {
  const value = route.name as string;
  // 排除移动端
  if (route.meta && route.meta.title && route.meta.type !== "mobile") {
    navList.value.push({
      value,
      label: route.meta.title as string,
    });
  }
});
const windowResizeFun = () => {
  isMobileFlag.value = isMobile();
};
onMounted(() => {
  window.addEventListener("resize", windowResizeFun, false);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", windowResizeFun);
});
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.page-header {
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 80px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  .show-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1280px;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      min-width: 200px;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #2e4554;
    }
    .nav-list {
      flex: 1;
      max-width: 600px;
      padding: 0 40px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      margin: 0;
      .nav-item {
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        a {
          font-size: 20px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0em;
          color: #222222;
          text-decoration: none;
        }
        &.actived {
          a {
            color: #ff6f00;
          }
        }
      }
    }
  }
}
.menu {
  width: 30px;
  height: 30px;
  background: url("@/assets/images/menu.png") no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 20px;
  color: #2e4554;
  cursor: pointer;
}
.drawer-menu {
  .nav-list {
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    list-style: none;
    margin: 0;
    .nav-item {
      margin: 0 0 1.5rem;
      width: 100%;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;
      a {
        display: block;
        font-size: 1.2rem;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0.2rem;
        color: #222222;
        text-decoration: none;
      }
      &.actived {
        a {
          color: #ff6f00;
        }
      }
    }
  }
}
</style>
