import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/courses",
    name: "courses",
    // route level code-splitting
    // this generates a separate chunk (courses.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "courses" */ "../views/CoursesView.vue"),
    meta: {
      title: "课程介绍",
    },
  },
  {
    path: "/study",
    name: "study",
    // route level code-splitting
    // this generates a separate chunk (study.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "study" */ "../views/StudyView.vue"),
    meta: {
      title: "学习成果",
    },
  },
  {
    path: "/team",
    name: "team",
    // route level code-splitting
    // this generates a separate chunk (team.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "team" */ "../views/TeamView.vue"),
    meta: {
      title: "师资团队",
    },
  },
  {
    path: "/volunteer",
    name: "volunteer",
    // route level code-splitting
    // this generates a separate chunk (volunteer.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "volunteer" */ "../views/VolunteerView.vue"),
    meta: {
      title: "志愿者招募",
    },
  },
  {
    path: "/connect",
    name: "connect",
    // route level code-splitting
    // this generates a separate chunk (connect.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "connect" */ "../views/ConnectView.vue"),
    meta: {
      title: "联系我们",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
