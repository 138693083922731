<template>
  <footer class="page-footer">
    <div class="connect-info">
      <div class="card">
        <div class="card-image">
          <img src="../assets/images/wx.jpg" alt="微信公众号" />
        </div>
        <div class="title">关注微信公众号</div>
      </div>
      <div class="dotline"></div>
      <div class="card">
        <div class="card-image">
          <img src="../assets/images/wx.jpg" alt="微信客服" />
        </div>
        <div class="title">添加微信客服</div>
      </div>
      <div class="dotline"></div>
      <div class="infos">
        <div class="info">
          <i class="icon tel"></i>
          电话：13911231074
        </div>
        <div class="info">
          <i class="icon email"></i>
          邮箱：kf@liangziqishi.cn
        </div>
        <div class="info">
          <i class="icon address"></i>
          北京海淀区西三旗文化科技园
        </div>
      </div>
    </div>
    <p class="copy-right">
      {{ copyRightMsg }}
    </p>
  </footer>
</template>
<script setup lang="ts">
const copyRightMsg =
  "Copyright © 2014-2024 EEOA - All rights reserved. 京ICP备18039479号-1";
</script>
<style lang="scss" scoped>
.page-footer {
  background-color: #fff;
  width: 100vw;
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 0;
  text-align: center;
  .connect-info {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 20px;
      .card-image {
        width: 215px;
        height: 215px;
        border: 1px dashed #666666;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        margin: 10px 0;
        font-size: 24px;
        font-weight: 500;
        color: #3d3d3d;
      }
    }
    .dotline {
      width: 1px;
      height: 217px;
      border-left: 1px dotted #ddd;
    }
    .infos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 215px;
      align-items: start;
      .info {
        display: flex;
        align-items: center;
        text-align: left;
        overflow: hidden;
        max-width: 450px;
        margin: 10px 0;
        font-size: 24px;
        font-weight: 500;
        color: #3d3d3d;
        .icon {
          margin-right: 10px;
          &.tel {
            background: url("../assets/images/tel.png") no-repeat;
            width: 30px;
            height: 30px;
          }
          &.email {
            background: url("../assets/images/email.png") no-repeat;
            width: 30px;
            height: 30px;
          }
          &.address {
            background: url("../assets/images/address.png") no-repeat;
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .copy-right {
    margin: 65px auto 0;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    color: #666666;
  }
}
@media screen and (max-width: 960px) {
  .page-footer {
    .connect-info {
      flex-wrap: wrap;
      .card {
        margin: 0 4vw;
        .card-image {
          width: 40vw;
          height: 40vw;
        }
        .title {
          margin: 10px 0;
          font-size: 1rem;
          font-weight: 500;
          color: #3d3d3d;
        }
      }
      .infos {
        justify-content: center;
        align-items: center;
        height: auto;
        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .icon {
            margin: 2px;
          }
        }
      }
    }
    .copy-right {
      font-size: 1rem;
    }
  }
}
</style>
