import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import router from "./router";

const vue = createApp(App);
vue.use(router);
vue.use(ElementPlus);
vue.mount("#app");
