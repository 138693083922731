import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/images/logo.png'


const _hoisted_1 = { class: "page-header" }
const _hoisted_2 = { class: "show-area" }
const _hoisted_3 = {
  key: 1,
  class: "nav-list"
}
const _hoisted_4 = { class: "nav-list" }

import { isMobile } from "@/utils";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
interface Option {
  value: string;
  label: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PageHeader',
  setup(__props) {

const isShowMenu = ref(false);
const isMobileFlag = ref(isMobile());
// 引入路由，动态展示路由meta信息
const routers = useRouter();
const currentRoute = useRoute();
const currentRouteName = ref(currentRoute.name as string);
const resetScrollAndShow = () => {
  const htmlDom = document.querySelector("html");
  if (htmlDom && htmlDom.scrollTop > 0) {
    htmlDom.scrollTo(0, 0);
  }
  isShowMenu.value = false;
};
watch(currentRoute, (to) => {
  resetScrollAndShow();
  currentRouteName.value = to.name as string;
});
const navList = ref<Option[]>([]);
routers.getRoutes().forEach((route) => {
  const value = route.name as string;
  // 排除移动端
  if (route.meta && route.meta.title && route.meta.type !== "mobile") {
    navList.value.push({
      value,
      label: route.meta.title as string,
    });
  }
});
const windowResizeFun = () => {
  isMobileFlag.value = isMobile();
};
onMounted(() => {
  window.addEventListener("resize", windowResizeFun, false);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", windowResizeFun);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "logo" }, [
        _createElementVNode("img", {
          style: {"width":"50px","height":"50px"},
          src: _imports_0,
          alt: "logo"
        }),
        _createTextVNode(" 柠檬学堂 ")
      ], -1)),
      (isMobileFlag.value)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isShowMenu.value = !isShowMenu.value)),
            class: "icon menu"
          }))
        : _createCommentVNode("", true),
      (!isMobileFlag.value)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.value, (nav) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["nav-item", currentRouteName.value === nav.value ? 'actived' : '']),
                key: nav.value
              }, [
                _createVNode(_component_router_link, {
                  class: "router-item",
                  to: { name: nav.value }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(nav.label), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ], 2))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (isMobileFlag.value)
        ? (_openBlock(), _createBlock(_component_el_drawer, {
            key: 2,
            "append-to-body": "",
            modelValue: isShowMenu.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isShowMenu).value = $event)),
            size: "50%",
            "with-header": false,
            "modal-class": "drawer-menu"
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.value, (nav) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: _normalizeClass(["nav-item", currentRouteName.value === nav.value ? 'actived' : '']),
                    key: nav.value
                  }, [
                    _createVNode(_component_router_link, {
                      class: "router-item",
                      to: { name: nav.value }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(nav.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ], 2))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})