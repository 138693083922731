import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "content-info" }
const _hoisted_3 = { class: "info-card" }
const _hoisted_4 = { class: "show-cards" }
const _hoisted_5 = { class: "cards" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "msg" }
const _hoisted_8 = { class: "top-line" }
const _hoisted_9 = {
  key: 0,
  class: "tip-list"
}
const _hoisted_10 = { class: "lr-card" }
const _hoisted_11 = { class: "msg lr-msg" }
const _hoisted_12 = {
  key: 0,
  class: "lr-image"
}
const _hoisted_13 = { class: "lr-card rl-style" }
const _hoisted_14 = { class: "msg lr-msg" }
const _hoisted_15 = {
  key: 0,
  class: "lr-image"
}
const _hoisted_16 = { class: "lr-card" }
const _hoisted_17 = { class: "msg lr-msg" }
const _hoisted_18 = {
  key: 0,
  class: "lr-image"
}
const _hoisted_19 = { class: "lr-card rl-style" }
const _hoisted_20 = { class: "msg lr-msg" }
const _hoisted_21 = {
  key: 0,
  class: "lr-image"
}
const _hoisted_22 = { class: "content-ai" }
const _hoisted_23 = { class: "content" }
const _hoisted_24 = { class: "btns" }
const _hoisted_25 = { class: "show-image" }
const _hoisted_26 = { class: "pingjia" }
const _hoisted_27 = { class: "area" }
const _hoisted_28 = {
  class: "content",
  ref: "pingjiaRef"
}
const _hoisted_29 = { class: "pingjia-list" }
const _hoisted_30 = { class: "first-line" }
const _hoisted_31 = { class: "title" }
const _hoisted_32 = { class: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_ArrowLeftBold = _resolveComponent("ArrowLeftBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ArrowRightBold = _resolveComponent("ArrowRightBold")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_carousel, {
      height: "36.4vw",
      arrow: "never"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carouselList, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_carousel_item, { key: index }, {
            default: _withCtx(() => [
              _createVNode(_component_el_image, {
                fit: "cover",
                src: item,
                class: "image"
              }, null, 8, ["src"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[14] || (_cache[14] = _createElementVNode("h1", null, "专研少儿英语我们是专业的", -1)),
      _cache[15] || (_cache[15] = _createElementVNode("p", null, "为新时代的孩子量身定制，用科技为学习英语赋能", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_image, {
            class: "show-image",
            fit: "cover",
            src: _ctx.activeCard.image
          }, null, 8, ["src"]),
          _createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardImageList, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                onClick: ($event: any) => (_ctx.activeCard = item)
              }, [
                _createVNode(_component_el_image, {
                  class: "card-item",
                  fit: "cover",
                  src: item.image
                }, null, 8, ["src"])
              ], 8, _hoisted_6))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.activeCard.title), 1),
            (_ctx.activeCard.tips && _ctx.activeCard.tips.length)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeCard.tips, (tip) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: "tip",
                      key: tip
                    }, _toDisplayString(tip), 1))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.activeCard.info), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (_ctx.isMobileFlag)
            ? (_openBlock(), _createBlock(_component_el_image, {
                key: 0,
                class: "mobile-image",
                src: _ctx.mobileImgs[0]
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          _cache[2] || (_cache[2] = _createElementVNode("h2", null, "英语能力测评", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("p", null, " 精准测评界面，通过分析孩子的语言能力，为其量身推荐合适的练习题目。科学的反馈机制帮助家长和学生直观了解学习成果，并针对薄弱环节提供专项训练，提升学习效率。 ", -1)),
          _cache[4] || (_cache[4] = _createElementVNode("ul", { class: "tip-list" }, [
            _createElementVNode("li", { class: "tip" }, "学习方式更加自主和灵活"),
            _createElementVNode("li", { class: "tip" }, "成本低"),
            _createElementVNode("li", { class: "tip" }, "学习氛围更轻松")
          ], -1))
        ]),
        (!_ctx.isMobileFlag)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_el_image, {
                style: {"height":"476px"},
                class: "bg-image",
                fit: "cover",
                src: _ctx.lrImages[0]
              }, null, 8, ["src"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (_ctx.isMobileFlag)
            ? (_openBlock(), _createBlock(_component_el_image, {
                key: 0,
                class: "mobile-image",
                src: _ctx.mobileImgs[1]
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          _cache[5] || (_cache[5] = _createElementVNode("h2", null, "AI 聊天", -1)),
          _cache[6] || (_cache[6] = _createElementVNode("p", null, " 模拟真实场景，孩子可与AI展开多角色对话，体验如购物、旅行等多样化主题，提高听力和语感。互动式对话帮助孩子增强语言表达能力，让英语交流更加自然自信！ ", -1)),
          _cache[7] || (_cache[7] = _createElementVNode("ul", { class: "tip-list" }, [
            _createElementVNode("li", { class: "tip" }, "互动式学习"),
            _createElementVNode("li", { class: "tip" }, "模拟真实环境"),
            _createElementVNode("li", { class: "tip" }, "AI智能辅助")
          ], -1))
        ]),
        (!_ctx.isMobileFlag)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_component_el_image, {
                class: "bg-image",
                fit: "cover",
                src: _ctx.lrImages[1]
              }, null, 8, ["src"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          (_ctx.isMobileFlag)
            ? (_openBlock(), _createBlock(_component_el_image, {
                key: 0,
                class: "mobile-image",
                src: _ctx.mobileImgs[2]
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          _cache[8] || (_cache[8] = _createElementVNode("h2", null, "AI 听写", -1)),
          _cache[9] || (_cache[9] = _createElementVNode("p", null, " 通过音节划分、单词提示等智能功能，让听写变得有趣高效。科学记忆法帮助孩子告别枯燥的单词记忆，轻松掌握更多词汇，提高语言学习效率。 ", -1)),
          _cache[10] || (_cache[10] = _createElementVNode("ul", { class: "tip-list" }, [
            _createElementVNode("li", { class: "tip" }, "AI高效学习"),
            _createElementVNode("li", { class: "tip" }, "多元化学习方式"),
            _createElementVNode("li", { class: "tip" }, "精准教学")
          ], -1))
        ]),
        (!_ctx.isMobileFlag)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createVNode(_component_el_image, {
                class: "bg-image",
                fit: "cover",
                src: _ctx.lrImages[2]
              }, null, 8, ["src"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          (_ctx.isMobileFlag)
            ? (_openBlock(), _createBlock(_component_el_image, {
                key: 0,
                class: "mobile-image",
                src: _ctx.mobileImgs[3]
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          _cache[11] || (_cache[11] = _createElementVNode("h2", null, "用户上传视频制作课程", -1)),
          _cache[12] || (_cache[12] = _createElementVNode("p", null, " 孩子和家长可以选择喜爱的电影或电视剧片段制作个性化课程。通过生动有趣的情境学习，让孩子在熟悉的画面中自然吸收语言知识，告别传统枯燥模式，激发学习动力。 ", -1)),
          _cache[13] || (_cache[13] = _createElementVNode("ul", { class: "tip-list" }, [
            _createElementVNode("li", {
              style: {"margin-right":"0px"},
              class: "tip"
            }, "个性化学习方式"),
            _createElementVNode("li", {
              style: {"margin-right":"0px"},
              class: "tip"
            }, "激发自主能动性"),
            _createElementVNode("li", { class: "tip" }, "创新学习模式")
          ], -1))
        ]),
        (!_ctx.isMobileFlag)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _createVNode(_component_el_image, {
                class: "bg-image",
                fit: "cover",
                src: _ctx.lrImages[3]
              }, null, 8, ["src"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _cache[17] || (_cache[17] = _createElementVNode("h1", null, "让 AI 陪你一起学英语，让你学的更加简单有趣。", -1)),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("ul", _hoisted_24, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.btns, (btn, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createVNode(_component_el_image, { src: btn }, null, 8, ["src"])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createVNode(_component_el_image, { src: _ctx.aiImage }, null, 8, ["src"]),
          _cache[16] || (_cache[16] = _createElementVNode("p", { style: {"font-size":"18px"} }, " 让AI陪你一起学英语，让你学的更加简单有趣，让AI陪你一起学英语，让你学的更加简单有趣。 ", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _cache[19] || (_cache[19] = _createElementVNode("h1", null, "客户评价", -1)),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("ul", _hoisted_29, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pingjiaList, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "pingjia-item",
                key: index
              }, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("span", _hoisted_31, [
                    _createVNode(_component_el_avatar, {
                      size: 60,
                      src: item.avatar
                    }, null, 8, ["src"]),
                    _createTextVNode(" " + _toDisplayString(item.name), 1)
                  ]),
                  _createElementVNode("span", _hoisted_32, _toDisplayString(item.createAt), 1)
                ]),
                _cache[18] || (_cache[18] = _createStaticVNode("<div class=\"number\" data-v-9159c5f6><i class=\"icon star\" data-v-9159c5f6></i><i class=\"icon star\" data-v-9159c5f6></i><i class=\"icon star\" data-v-9159c5f6></i><i class=\"icon star\" data-v-9159c5f6></i><i class=\"icon star\" data-v-9159c5f6></i></div>", 1)),
                _createElementVNode("h4", null, _toDisplayString(item.title), 1),
                _createElementVNode("p", null, _toDisplayString(item.content), 1)
              ]))
            }), 128))
          ])
        ], 512),
        _createElementVNode("i", {
          class: "icon-btn left",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleMove('left')))
        }, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_ArrowLeftBold)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("i", {
          class: "icon-btn right",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleMove('right')))
        }, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_ArrowRightBold)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}